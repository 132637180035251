export function getParents(element: HTMLElement, selector?: string): HTMLElement[] {
    const parents: HTMLElement[] = [];
    let current = element.parentElement;

    if (current === null) {
        return parents;
    }

    while (current.parentElement !== null && current.parentElement !== document.documentElement) {
        if (selector) {
            if (current.matches(selector)) {
                parents.push(current);
            }
        } else {
            parents.push(current);
        }

        current = current.parentElement;
    }

    return parents;
}

export type IterableHTMLElements = HTMLElement[] | NodeListOf<HTMLElement>;

export function isListOfElements(el: HTMLElement | IterableHTMLElements): el is IterableHTMLElements {
    return typeof el === 'object' && (Array.isArray(el) || Object.prototype.toString.call(el).includes('NodeList'));
}

export function getSiblings(element: HTMLElement | IterableHTMLElements, selector?: string): HTMLElement[] {
    if (isListOfElements(element)) {
        const allSiblings: HTMLElement[] = [];
        for (const el of element) {
            allSiblings.push(...getSiblings(el, selector));
        }

        return allSiblings.filter((el, index, arr) => arr.indexOf(el) === index);
    }

    if (!element.parentElement) {
        return [];
    }

    const targets = selector ?
        element.parentElement.querySelectorAll(':scope > ' + selector):
        element.parentElement.children;

    const siblings: HTMLElement[] = [];

    for (const target of targets) {
        if (target !== element) {
            siblings.push(target as HTMLElement);
        }
    }

    return siblings;
}

export function insertAfter(target: HTMLElement, newElement: HTMLElement): void {
    if (!target.parentNode) {
        throw new Error('Target element has no parent.');
    }

    target.parentNode.insertBefore(newElement, target.nextSibling);
}
