import { isListOfElements, IterableHTMLElements } from '@/utils/dom/elements';

type DisplayProp =
    /* pre-composed values */
    'block' | 'inline' | 'inline-block' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid' | 'flow-root' |
    /* box generation */
    'none' | 'contents' |
    /* multi-keyword syntax */
    'block flow' | 'inline flow' | 'inline flow-root' | 'block flex' | 'inline flex' | 'block grid' | 'inline grid' | 'block flow-root' |
    /* other values */
    'table' | 'table-row' | 'list-item' |
    /* Global values */
    'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset';

/* Support jQuery-like params */
type DurationProp = number | 'slow' | 'fast';

/**
 * Defines how long the animations will run for
 */
const defaultAnimationDuration = 400;
const fastAnimationDuration = 200;
const slowAnimationDuration = 600;

/**
 * Fade element out of view
 */
export function fadeOut(el: HTMLElement, duration: DurationProp = defaultAnimationDuration): void {
    const animationDuration = duration === 'slow' ? slowAnimationDuration : (duration === 'fast' ? fastAnimationDuration : duration);

    el.style.opacity = '1';
    let startTime: number;

    function fade(timestamp: number) {
        if (!startTime) {
            startTime = timestamp;
        }

        const runtime = timestamp - startTime;

        el.style.opacity = Math.max(1 - (runtime / animationDuration), 0).toString();

        if (runtime < animationDuration) {
            window.requestAnimationFrame(fade);
        } else {
            el.style.opacity = '0';
            el.style.display = 'none';
        }
    }

    window.requestAnimationFrame(fade);
}

export function fadeIn(el: HTMLElement, duration: DurationProp = defaultAnimationDuration, display: DisplayProp = 'block'): void {
    fadeTo(el, 1.0, duration, display);
}

export function fadeTo(el: HTMLElement, opacity: number, duration: DurationProp = defaultAnimationDuration, display: DisplayProp = 'block'): void {
    const animationDuration = duration === 'slow' ? slowAnimationDuration : (duration === 'fast' ? fastAnimationDuration : duration);

    el.style.opacity = '0';
    el.style.display = display;
    let startTime: number;

    function fade(timestamp: number) {
        if (!startTime) {
            startTime = timestamp;
        }

        const runtime = timestamp - startTime;

        el.style.opacity = (Math.min(runtime / animationDuration, 1) * opacity).toString();

        if (runtime < animationDuration) {
            window.requestAnimationFrame(fade);
        } else {
            el.style.opacity = opacity.toString();
        }
    }

    window.requestAnimationFrame(fade);
}

export function show(el: HTMLElement | IterableHTMLElements, displayValue: DisplayProp = 'block'): void {
    if (isListOfElements(el)) {
        for (const element of el) {
            show(element, displayValue);
        }

        return;
    }

    if (el.hasAttribute('data-old-display')) {
        el.style.display = el.getAttribute('data-old-display')!;
        el.attributes.removeNamedItem('data-old-display');

        return;
    }

    el.style.display = displayValue;
}

export function hide(el: HTMLElement | IterableHTMLElements): void {
    if (isListOfElements(el)) {
        for (const element of el) {
            hide(element);
        }

        return;
    }

    if (el.style.display !== 'none') {
        el.dataset.oldDisplay = el.style.display;
        el.style.display = 'none';
    }
}
